<template>
  <div>
    <data-table
      ref="dataTable"
      :actions="dataTable.actions"
      :data-class="geoframe"
      :default-sort="{ sortBy: dataTable.sortBy, order: dataTable.order }"
      :page-size="dataTable.pageSize"
      permission-suffix="geoframes"
      @action="handleAction"
      @archive="value => showingArchive = value"
      @data-fetch="handleDataFetch"
    >
      <div
        slot="additional-controls"
        slot-scope="{ selectedItemCount, archiveView }"
      >
        <el-button
          v-if="hasPermission('archive_geoframes') && !archiveView"
          :disabled="selectedItemCount === 0"
          type="danger"
          @click="handleAction('archiveMulti')"
        >
          Archive Selected Geoframes
        </el-button>
        <el-button
          v-else-if="hasPermission('restore_geoframes') && archiveView"
          :disabled="selectedItemCount === 0"
          type="primary"
          @click="handleAction('archiveMulti', null, { restore: true })"
        >
          Restore Selected Geoframes
        </el-button>
      </div>
      <el-table-column
        prop="name"
        label="Geoframe"
        sortable="custom"
      />
      <el-table-column
        prop="count"
        label="Count"
        sortable="custom"
        :width="110"
      >
        <template slot-scope="data">
          <span v-if="data.row.countError">
            <i class="fa fa-exclamation-triangle" />
          </span>
          <span v-else-if="data.row.count_pending">
            <i class="fa fa-sync fa-spin" />
          </span>
          <span v-else-if="data.row.historical">
            <el-tooltip
              class="item"
              effect="light"
              content="Historical Geoframe"
            >
              <i class="far fa-calendar-alt" />
            </el-tooltip>
          </span>
          <span v-else>{{ data.row.count | numeral }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Tags">
        <template slot-scope="data">
          <el-tag
            v-for="tag in data.row.tags"
            :key="tag.id"
            size="mini"
          >
            {{ tag.name }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="start_date"
        label="Start"
        sortable="custom"
        :width="110"
      />
      <el-table-column
        prop="end_date"
        label="End"
        sortable="custom"
        :width="110"
      />
      <el-table-column
        prop="author"
        label="Author"
        :width="130"
        sortable="custom"
      >
        <template slot-scope="data">
          {{ data.row.author | fullName }}
        </template>
      </el-table-column>
      <el-table-column
        v-if="!showingArchive"
        :width="110"
        prop="created_at"
        label="Created"
        sortable="custom"
      >
        <template slot-scope="data">
          {{ data.row.created_at | dateString }}
        </template>
      </el-table-column>
      <el-table-column
        v-else
        :width="110"
        prop="deleted_at"
        label="Archived"
        sortable="custom"
      >
        <template slot-scope="data">
          {{ data.row.deleted_at | dateString }}
        </template>
      </el-table-column>
      <el-table-column
        prop="organization"
        label="Organization"
        sortable="custom"
        :width="150"
      >
        <template slot-scope="data">
          {{ data.row.organization.name }}
        </template>
      </el-table-column>
    </data-table>

    <edit-dialog
      :data="editingGeoframe"
      :el-rules="elementRules"
      :visible.sync="showEditDialog"
      width="50%"
      @save="handleSave"
    >
      <template slot-scope="{ formData }">
        <el-form-item
          label="Name"
          prop="name"
        >
          <el-input
            v-model="formData.name"
            placeholder="Geoframe Name"
          />
          <div>
            <dedupe-control v-model="dedupe" />
          </div>
        </el-form-item>
        <el-form-item label="Tags">
          <tag-picker :tags.sync="formData.tags" />
        </el-form-item>
      </template>
    </edit-dialog>

    <map-viewer :geoframe.sync="viewGeoframe" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DataTable from '../global/DataTable.vue';
import TagPicker from '../global/TagPicker.vue';
import MapViewer from '../global/MapViewer.vue';
import EditDialog from '../global/EditDialog.vue';
import DedupeControl from '../global/DedupeControl.vue';
import { geoframe } from '@/adonis-api';
import downloadFile from '@/helpers/download-file';
import DelayedAction from '@/helpers/delayed-action';
import jobWatcher from '@/mixins/job-watcher';
import { getValidationErrors } from '@/helpers/validation-rules';

export default {
  name: 'GeoframeLibraryTable',
  components: { DataTable, DedupeControl, EditDialog, MapViewer, TagPicker },
  mixins: [jobWatcher],
  data() {
    return {
      dataTable: Object.freeze({
        actions: [
          {
            command: 'edit',
            icon: 'edit',
            label: 'Edit Name & Tags',
            permissions: {
              all: ['edit_geoframes'],
            },
          },
          {
            command: 'view',
            icon: 'map-marked-alt',
            label: 'View on Map',
            permissions: {
              all: ['display_geoframes'],
            },
          },
          {
            command: 'refreshCount',
            icon: 'sync-alt',
            label: 'Refresh Count',
            permissions: {
              all: ['refresh_geoframes'],
            },
            disabled: geoframe => geoframe.count_pending,
          },
          {
            command: 'exportGeojson',
            icon: 'file-export',
            label: 'Export GeoJSON',
            permissions: {
              all: ['geojson_export_geoframes'],
            },
          },
          {
            command: 'jobLink',
            icon: 'link',
            label: 'Job Details',
            flags: ['internal'],
            showAction: function(row) {
              return (row.job_id);
            },
          },
        ],
        order: 'descending',
        sortBy: 'created_at',
      }),
      dedupe: true,
      editingGeoframe: {},
      formErrors: {},
      geoEditForm: {
        id: '',
        name: '',
        tags: [],
      },
      showEditDialog: false,
      showingArchive: false,
      elementRules: {
        name: [
          {
            required: true,
            message: 'Geoframe name is required.',
            trigger: 'blur',
          },
        ],
      },
      viewGeoframe: null,
    };
  },

  computed: {
    ...mapGetters('user', ['hasPermission']),
  },

  watch: {
    watchedJobs: {
      handler(jobs) {
        const completedJobs = jobs.filter(
          job => job.payload.status === 'COMPLETE',
        );

        if (!completedJobs.length) return;

        for (const { geoframeId, count } of completedJobs.map(job => ({
          geoframeId: job.payload.result.id,
          count: job.payload.result.count,
        }))) {
          this.$refs.dataTable.updateItemById(geoframeId, {
            count_pending: false,
            count,
          });
        }
      },
      deep: true,
    },
  },

  created() {
    this.geoframe = geoframe;
  },

  methods: {
    async handleAction(command, geoframe, options = {}) {
      let job, response, delayedAction;

      switch (command) {
        case 'edit':
          this.geoEditForm.id = geoframe.id;
          this.geoEditForm.name = geoframe.name;
          this.geoEditForm.tags = geoframe.tags;
          this.showEditDialog = true;
          this.editingGeoframe = geoframe;
          break;

        case 'refreshCount':
          try {
            job = await this.geoframe.getCount(geoframe.id);
            this.$refs.dataTable.updateItemById(geoframe.id, {
              count_pending: true,
            });
            this.$store.dispatch('mqtt/addRawJob', job);
            this.watchJob(job.id);

            this.$message({
              message: `Count for "${geoframe.name}" being processed.`,
              customClass: 'el-message--info',
              iconClass: 'el-message__icon fa fa-clock',
              duration: 4000,
            });
            window.mixpanel.track('Refresh Count', {
              ID: geoframe.id,
              Name: geoframe.name,
            });
          } catch (e) {
            this.$notify.error({
              message: 'Geoframe count failed. This issue has been reported.',
              title: 'Count Failed',
            });
            this.$reportError(e, {
              componentName: this.$options.name,
              geoframeId: geoframe.id,
              geoframeName: geoframe.name,
            });
          }

          break;

        case 'exportGeojson':
          (delayedAction = new DelayedAction(() => {
            this.$message('This might take a while, stand by...');
          }, 1500)).run();

          response = await this.geoframe.exportGeojson(geoframe.id);
          delayedAction.cancel();

          downloadFile(response, {
            filename: `${geoframe.name}.geojson`,
            mimeType: 'application/vnd.geo+json',
            prettify: false,
          });
          window.mixpanel.track('Export Geoframe GeoJSON', {
            ID: geoframe.id,
            Name: geoframe.name,
          });

          break;

        case 'archiveMulti':
          try {
            await this.$confirm(
              `Are you sure you want to ${
                options.restore ? 'restore' : 'archive'
              } the ${
                this.$refs.dataTable.selectedItems.length
              } selected geoframes?`,
              `${options.restore ? 'Restore' : 'Archive'} Geoframes`,
              {
                type: 'warning',
              },
            );

            const ids = this.$refs.dataTable.selectedItems.map(row => row.id);

            // Clear row selections
            await this.geoframe.bulkDelete(ids, options);

            this.$refs.dataTable.clearSelection();
            this.$refs.dataTable.invokeQuery();
            this.$message('Geoframes archived.');

            window.mixpanel.track('Archive Geoframes', {
              'Archived IDs': ids,
            });
          } catch (e) {
            if (e !== 'cancel') {
              this.$reportError(e);
            }
          }
          break;

        case 'view':
          try {
            response = await this.geoframe.findOne(geoframe.id, { geojson: 1 });
            this.viewGeoframe = {
              name: geoframe.name,
              feature: response.geodata.geojson,
            };
          } catch (e) {
            this.$reportError(e);
          }

          break;

        case 'jobLink':
          try {
            this.$router.push(`/jobs/${geoframe.job_id}`);
          } catch (e) {
            this.$reportError(e);
          }
          break;

        default:
          break;
      }
    },

    handleDataFetch(geoframes) {
      const geoframeJobIds = geoframes.map(geo => geo.job_id).filter(id => id);
      this.setWatchedJobs(geoframeJobIds);
    },

    async handleSave(data) {
      try {
        const response = await this.geoframe.update(data.id, {
          ...data,
          auto_dedupe: this.dedupe,
        });
        this.$refs.dataTable.updateItemById(data.id, response.data);
        this.showEditDialog = false;
      } catch (e) {
        if (e.response) this.$emit('error', getValidationErrors(e));
        else {
          this.$notify.error({
            message:
              'There was a problem updating this item. This issue has been reported.',
          });
          this.$reportError(e, {
            componentName: this.$options.name,
            formData: data,
          });
        }
      }
    },

    sortCaseInsensitive(a, b) {
      if (a > b) return 1;
      else if (b > a) return -1;
      return 0;
    },

    async updateGeoframe() {
      const { name, tags } = this.geoEditForm;

      this.showEditDialog = false;
      this.editingGeoframe.set({
        name,
        tags,
        organizationId: this.editingGeoframe.organization.id,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.el-dropdown {
  font-size: 1em;
}

.el-dropdown-menu {
  font-size: 0.75em;
}

.el-tag {
  margin-left: 4px;
}
</style>
