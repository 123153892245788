<template>
  <el-dialog
    :before-close="handleBeforeClose"
    :title="geoframe && geoframe.name"
    :visible="!!geoframe"
    width="90%"
  >
    <template v-if="geoframe">
      <div
        v-if="geoframe.geoframeCreateLink"
        style="margin-bottom:1em;"
      >
        <a
          :href="geoframe.geoframeCreateLink"
          target="_blank"
        >Draw New Geoframe</a>
      </div>
      <div v-if="geoframe.googleLink">
        <a
          :href="geoframe.googleLink"
          target="_blank"
          rel="noopener"
        >Google Map</a>
        <h3 v-if="geoframe.geodataId">
          Geodata ID: {{ geoframe.geodataId }}
        </h3>
      </div>
      <poly-map :geojson="geoframe.feature" />
    </template>
  </el-dialog>
</template>

<script>
import PolyMap from './PolyMap';

export default {
  components: { PolyMap },
  props: {
    geoframe: { type: Object, default: null },
  },

  methods: {
    handleBeforeClose(done) {
      this.$emit('update:geoframe', null);
      done();
    },
  },
};
</script>

<style>

</style>
