export default function downloadFile(
  data,
  { filename, mimeType, prettify } = {},
) {
  const link = document.createElement('a');
  let blob;

  switch (mimeType) {
    case 'text/csv':
      blob = new Blob([data], {
        mimeType,
      });
      break;
    default:
      blob = new Blob([JSON.stringify(data, null, prettify ? 2 : 0)], {
        mimeType,
      });
      break;
  }

  link.style.display = 'none';
  link.href = window.URL.createObjectURL(blob);
  link.download = filename;
  document.body.appendChild(link);
  link.click();

  // Some browsers may fail to download if the link is removed too quickly
  setTimeout(() => {
    document.body.removeChild(link);
  }, 100);
}
