<template>
  <div>
    <h3>Geoframe Library</h3>
    <geoframe-library-table />
  </div>
</template>

<script>
import GeoframeLibraryTable from '../../../components/geoframe/GeoframeLibraryTable';
export default {
  components: { GeoframeLibraryTable },

  data() {
    return {
      // Geoframes,
    };
  },
};
</script>
