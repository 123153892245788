export default class DelayedAction {
  constructor(callback, delay) {
    if (typeof callback !== 'function') {
      throw new Error('DelayedAction expects a callback function');
    }
    if (typeof delay !== 'number') {
      throw new Error('DelayedAction expects a delay in milliseconds');
    }
    this.callback = callback;
    this.delay = delay;
    this.called = false;
  }

  /**
   * Cancels the action so it won't call the callback
   */
  cancel() {
    if (this.timer && !this.called) clearTimeout(this.timer);
  }

  /**
   * Resets the timer back to its original value, as long as the callback hasn't
   * been called, and starts the timer again.
   */
  reset() {
    if (this.timer && !this.called) {
      clearTimeout(this.timer);
      this.run();
    }
  }

  /**
   * Starts the timer (delay), calls the callback when it runs out
   */
  run() {
    this.timer = setTimeout(() => {
      this.callback();
      this.called = true;
    }, this.delay);
  }
}
